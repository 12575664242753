<template>
    <div class="exper">
      <SuccessImg :list="successList" :id="16" />
	   <Pagination :page="form.pageNum" :pageSize="form.pageSize"  :total="successTotal" @handleList="handleList" />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SuccessImg from '../components/success_img.vue'
import Pagination from '../components/pagination.vue'
export default {
    data() {
        return {
                 form:{
            type:'success_management',
            pageNum:1,
            pageSize:6,
          },
		};
    },
	components:{
		SuccessImg,
		Pagination
	},
      computed:{
      ...mapState(['successList','successTotal'])
    },
    mounted() {
      this.handelSuccessList(this.form)
    },
    methods: {
     ...mapActions(['handelSuccessList']),
		handleList(e){
			this.form.pageNum=e
      this.handelSuccessList(this.form)
		}
    }
};
</script>

<style scoped lang='less'>


</style>